import { createContext } from "react";
import socketio from "socket.io-client";

const userId = JSON.parse(sessionStorage.getItem("ccAuth"))?.id;
// export const socket = socketio.connect("fizzle.gamequiz.live", {
//   query: {
//     userId,
//   },
// });
export const socket={}
export const SocketContext = createContext();
