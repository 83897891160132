import React, { useEffect } from "react";

function Chatwoot() {
  useEffect(() => {
    function setupWoot(d, t) {
      var BASE_URL = "https://teamhubpm.9ijakids.com";
      var g = d.createElement(t),
        s = d.getElementsByTagName(t)[0];
      g.src = BASE_URL + "/packs/js/sdk.js";
      g.defer = true;
      g.async = true;
      s.parentNode.insertBefore(g, s);
      g.onload = function () {
        window.chatwootSDK.run({
          websiteToken: "Hv3rubMrXxst2ZHfY8cc5NNx",
          baseUrl: BASE_URL,
        });
      };
    }

    setupWoot(document, "script");
  }, []);

  return null;
}

export default Chatwoot;
